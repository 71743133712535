import React from 'react';
import ProductsSec from '../components/Home Page Components/Products Section/ProductsSec';

const ProductsPage = () => {
    return (
        <main>
            <section className='pt-44'>
                {/* heading  */}
                <div className='flex flex-col items-center justify-center space-y-2' data-aos="zoom-in-right" data-aos-duration="1000">
                    <h1 className='text-gray-700 text-2xl lg:text-3xl uppercase'>Products</h1>
                    <div className='h-1 w-16 rounded-md bg-primary'></div>
                </div>


                {/* products  */}
                <ProductsSec />

                
            </section>
        </main>
    );
};

export default ProductsPage;