import React from 'react';
import { BiWorld } from 'react-icons/bi';
import { BsFacebook, BsFillEnvelopeFill, BsFillTelephoneFill, BsInstagram, BsLinkedin, BsWhatsapp } from 'react-icons/bs';
import { MdOutlineNavigateNext } from 'react-icons/md';
import { RiUser2Fill } from 'react-icons/ri';
import { Link } from "react-router-dom";

const IMPORTANT_LINKS = [
    { id: 1, text: 'Home', link: "/" },
    { id: 2, text: 'About', link: "/about" },
    { id: 5, text: 'Products', link: "/products" },
    { id: 3, text: 'Location', link: "/location" },
    { id: 4, text: 'Contact', link: "/contact" },
]

const ADDRESS = [
    { id: 1, text: 'House', rep: "06" },
    { id: 2, text: 'FLat', rep: "5C" },
    { id: 3, text: 'Road', rep: "14/A" },
    { id: 4, text: 'Sector', rep: "04" },
    { id: 5, text: 'Area', rep: "Uttara, Dhaka" },
]

const CONTACT = [
    { id: 1, text: 'Head', rep: "Monowar Hossain", icon: <RiUser2Fill /> },
    { id: 2, text: 'Tell', rep: "01713-117674", icon: <BsFillTelephoneFill /> },
    { id: 3, text: 'Web', rep: "www.hongyuanmetal.net", icon: <BiWorld /> },
    { id: 4, text: 'Email', rep: "monower@hongyuanmetal.net", icon: <BsFillEnvelopeFill /> }
]


const Footer = () => {
    let currentYear = new Date().getFullYear() 

    return (
        <footer className='mt-8'>
            {/* main footer  */}
            <section className='bg-white py-20'>

                <div className='max-w-screen-lg mx-auto px-6 grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-6'>

                    {/* ===================== logo =========================== */}
                    <div data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom" data-aos-duration="1000"> 
                        <img className='w-40 h-28 object-contain' src="../../../images/logo.jpg" alt="logo" />
                    </div>

                    {/* ===================== important links  =========================== */}

                    <div className='flex flex-col space-y-4 ' data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom" data-aos-duration="1000" >
                        <div className='flex flex-row space-x-2'>
                            <div className='h-6 w-1 rounded-md bg-primary'></div>
                            <h2 className='text-gray-700'>Important Links</h2>
                        </div>

                        {/* links  */}
                        <div className='flex flex-col space-y-2 text-gray-500 text-sm'>
                            {IMPORTANT_LINKS?.map(item => {
                                return (
                                    <Link to={item?.link} key={item?.id} className="flex flex-row space-x-2 items-center">
                                        <MdOutlineNavigateNext />
                                        <span className='hover:underline cursor-pointer'>{item?.text}</span>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>


                    {/* ===================== Address  =========================== */}
                    <div className='flex flex-col space-y-4 ' data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
                        <div className='flex flex-row space-x-2'>
                            <div className='h-6 w-1 rounded-md bg-primary'></div>
                            <h2 className='text-gray-700'>Bangladesh Address</h2>
                        </div>

                        {/* address  */}
                        <div className='flex flex-col space-y-2 text-gray-500 text-sm'>
                            {ADDRESS?.map(item => {
                                return (
                                    <div key={item?.id} className="flex flex-row space-x-2 items-center">
                                        <span>{item?.text}</span>  <span> : </span> <span>{item?.rep}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>


                    {/* ===================== Contact  =========================== */}
                    <div className='flex flex-col space-y-4 ' data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
                        <div className='flex flex-row space-x-2'>
                            <div className='h-6 w-1 rounded-md bg-primary'></div>
                            <h2 className='text-gray-700'>Contact</h2>
                        </div>

                        {/* links  */}
                        <div className='flex flex-col space-y-2 text-gray-500 text-sm'>
                            {CONTACT?.map(item => {
                                return (
                                    <div key={item?.id} className="flex flex-row space-x-1 items-center">
                                        <span>{item?.icon}</span>
                                        <span>{item?.text}</span>
                                        <span> : </span>
                                        <span>{item?.rep}</span>
                                    </div>
                                )
                            })}
                        </div>

                        {/* social media icons  */}
                        <div className='flex flex-row items-center space-x-3'>
                            <a className='text-gray-600 hover:text-primary' rel="noreferrer" href='https://www.facebook.com' target="_blank"><BsFacebook /></a>
                            <a className='text-gray-600 hover:text-primary' rel="noreferrer" href='https://www.facebook.com' target="_blank"><BsInstagram /></a>
                            <a className='text-gray-600 hover:text-primary' rel="noreferrer" href='https://www.facebook.com' target="_blank"><BsWhatsapp /></a>
                            <a className='text-gray-600 hover:text-primary' rel="noreferrer" href='https://www.facebook.com' target="_blank"><BsLinkedin /></a>
                        </div>
                    </div>


                </div>

            </section>

            {/* bottom footer  */}
            <div className='bg-gray-200 py-4 border-t-2 border-gray-300' data-aos="fade-up"
                data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
                <div className='max-w-screen-lg mx-auto px-4 lg:px-0 '>
                    <h3 className='text-center text-xs'>{currentYear} Copyright © Hongyuan. All rights reserved.</h3>
                </div>
            </div>
        </footer>
    );
};

export default Footer;