import React from 'react';

const LocationPage = () => {
    return (
        <main>
            <section className='pt-44'>
                {/* heading  */}
                <div className='flex flex-col items-center justify-center space-y-2 mb-10' data-aos="zoom-in-right" data-aos-duration="1000">
                    <h1 className='text-gray-700 text-2xl lg:text-3xl uppercase'>Find us on the map</h1>
                    <div className='h-1 w-16 rounded-md bg-primary'></div>
                </div>

              
                <iframe title="hongyuan" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228.04364387605244!2d90.40073343228444!3d23.86484471769977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c502ca70e809%3A0xcd93ca61f9a15cc6!2sHongyuan!5e0!3m2!1sen!2sbd!4v1673804937646!5m2!1sen!2sbd" className='w-full' height="600" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>
        </main>
    );
};

export default LocationPage;


