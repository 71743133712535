import React from 'react';
import ContactForm from '../components/Contact Page Components/ContactForm';

const ContactPage = () => {
    return (
        <main>
            <section className='pt-36 max-w-screen-lg mx-auto px-6'>

                {/* heading  */}
                <div className='flex flex-col items-center justify-center space-y-2 mb-12' data-aos="zoom-in-right" data-aos-duration="1000">
                    <h1 className='text-gray-700 text-2xl lg:text-3xl uppercase'>Contact Us</h1>
                    <div className='h-1 w-16 rounded-md bg-primary'></div>
                </div>

                <div className='flex flex-col md:flex-row lg:flex-row space-y-8 md:space-x-10 lg:space-x-10 justify-between'>

                    {/* left text  */}
                    <div className='flex flex-col space-y-2 ' data-aos="zoom-in-right" data-aos-duration="1000">
                        <h1 className='text-3xl'>Have any Questions ? </h1>
                        <span className='text-sm text-gray-800 font-light'>Contact Us or book an appointment online</span>
                    </div>
                    
                    {/* right text  */}
                    <div data-aos="zoom-in-left" data-aos-duration="1000">
                        <ContactForm />
                    </div>

                </div>
            </section>
        </main>
    );
};

export default ContactPage;