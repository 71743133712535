
import React from 'react';
import { Route, Routes } from "react-router-dom";
import Footer from './components/Footer Section/Footer';
import Navbar from './components/Navbar';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import LocationPage from './pages/LocationPage';
import ProductDetail from './pages/ProductDetail';
import ProductsPage from './pages/ProductsPage';

const App = () => {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/location" element={<LocationPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/:productname" element={<ProductDetail />} />
      </Routes>
      <Footer />

    </div>
  );
};

export default App;