import React from 'react';

const Heading = ({title,subTitle}) => {
    return (
        <div>
            <div className='flex flex-col justify-center items-center space-y-3'  >
                <span className='text-sm lg:text-base text-primary'>{subTitle}</span>
                <h1 className='text-2xl md:text-2xl lg:text-3xl text-gray-800'>{title}</h1>
            </div>
        </div>
    );
};

export default Heading;