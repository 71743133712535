import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Home Page Components/Button';
import Heading from '../Home Page Components/Heading/Heading';

const SubFooter = () => {
    const [engagement, setEngagement] = useState([])

    useEffect(() => {
        axios.get('./engagement.json')?.then(res => {
            setEngagement(res?.data?.engagements)
        })

    }, [])

    return (
        <>
            <section className='max-w-screen-lg mx-auto py-16 overflow-hidden'>

                <div>

                    {/* heading  */}
                    <div data-aos="zoom-in-right" data-aos-duration="1000">
                        <Heading title="How It Works" subTitle="ENGAGEMENT" />
                    </div>

                    <div>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-16'>
                            {engagement?.map(item => {
                                return (
                                    <div key={item?.id} className="justify-center items-center overflow-hidden flex flex-col space-y-5" data-aos="zoom-in" data-aos-duration="1000">

                                        <div className='rounded-full h-18 w-18 bg-white p-4 box-border '>

                                            <img className='w-16 object-contain h-full transform hover:translate-x-20  transition duration-500' src={item?.img} alt={item?.title} />


                                        </div>
                                        <h3 className='text-center text-gray-700 text-lg'>{item?.title}</h3>
                                    </div>
                                )
                            })}
                        </div>

                        <div className='flex flex-row justify-center items-center pt-12' data-aos="zoom-in-right" data-aos-duration="1000">
                            <Link to="/contact">
                                <Button title="BOOK YOUR FREE CONSULTATION" />
                            </Link>
                        </div>

                    </div>

                </div>

            </section>



        </>
    );
};

export default SubFooter;