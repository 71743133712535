import axios from 'axios';
import { React, useEffect, useState } from 'react';
import Heading from '../Heading/Heading';
import CustomerCard from './CustomerCard';

const CustomersSec = () => {
    const [customers, setCustomers] = useState([])
    const [activites,setActivites] = useState([])

    useEffect(() => {
        axios.get('./customers.json')?.then(res => {
            setCustomers(res?.data?.customers)
        })
        axios.get('./customers.json')?.then(res => {
            setActivites(res?.data?.activities)
        })

    }, [])


    return (
        <>
            <section className='max-w-screen-lg mx-auto py-16 overflow-hidden px-4 lg:px-0'>

                <div className='flex flex-col space-y-8'>

                    {/* heading  */}
                    <div className="text-center" data-aos="zoom-in-right" data-aos-duration="1000">
                        <Heading title="is proud to work with the best customers …" subTitle="OUR COMPANY" />
                    </div>

                    {/* customers logo  */}
                    <div className='grid grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-6'>
                        {customers?.map(item => {
                            return(
                                <div key={item?.id} className="w-24 overflow-hidden">
                                    <img className='object-contain w-full h-full' src={item?.img} alt={item?.title} data-aos="zoom-out-right" data-aos-duration="1000" />
                                </div>
                            )
                        })}
                    </div>


                </div>
            </section>


            {/* proud to count  */}
            <main className='bg-primary'>
                <section className='max-w-screen-lg mx-auto py-16 overflow-hidden px-4 lg:px-0'>
                     {/* heading  */}
                    <div data-aos="zoom-in-right" data-aos-duration="1000">
                        <h1 className='text-xl md:text-2xl lg:text-3xl text-white text-center'>… and we are proud to count</h1>
                     </div>

                     {/* proud to count  */}
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 pt-16'>
                        {activites?.map(item => {
                            return (
                                <CustomerCard item={item} />
                            )
                        })}
                    </div>

                </section>
            </main>
        </>
    );
};

export default CustomersSec;