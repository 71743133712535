import React from 'react';

const AboutSection = () => {
    return (
        <section className='max-w-screen-lg mx-auto  px-6 '>
            <div className='grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-x-5 p-8 box-border'>

                {/* img   */}
                <div className='col-span-2 rounded-lg lg:rounded-tl-lg lg:rounded-bl-lg overflow-hidden w-full md:h-64 lg:h-full pt-16'>
                    <img src="../../../images/wlcmpic.png" className='object-contain  w-full' alt="welcome" data-aos="fade-right" data-aos-duration="1000"  />
                </div>

                <div className='flex flex-col space-y-6 p-6 col-span-3' data-aos="fade-left" data-aos-duration="1000">
                    <p className='text-xs md:text-xs lg:text-xs text-gray-700 text-justify'>        Jiashan HongYuan Metal Products Co.,Ltd  was established in 1997 inChina’s hometown of buttons, The factory has a convent transportion as it is loctaed in the border of jiangsu zhejiang and shanghai provinces,and near the ancient town of xitang
                        Hongyuan is a professional manutacturer of the development,production and sales of buttons Its main prodution includes:resin buttons,metal buttons,plastic buttons,green button and cortical produtions. Resin buttons contain corrugated button,garland button,pearl button and abnormal button.</p>
                    <p className='text-xs md:text-xs lg:text-xs text-gray-700 text-justify'>Metal buttons resist of snap button,shank button,press studprong snap button,vapor eye rivet,hool and bar,cooper buckle,alloy buttonD buckle,Keyringbelt buckle,hoist buckle and so on.
                        Plastic buttons maitain eye-splice,spring stopper,bell stopper,bad adjuster,square buckle plastic snap button,plastic leather button,slider buckle and drill buttonGreen buttons include coconut button,wood button,horn button,Corozo buttons and Chines knot tassel
                        Cortical produtios are leather bell,PLbelt and pigskin productionetcHongyuan has realiable and win-win operation principlesnew management mode and exquisite technique.From the purchase of raw eateriats to the sales of the tinished production, Hongyuan has implements the quality control through the whole process.The factory welcome the visite,guidance and business negotiations from the triends from all walks of life.Hongyuan looking forward to the cooperation with you</p>
                </div>

            </div>


        </section>
    );
};

export default AboutSection;