import React from 'react';
import AboutSection from '../components/About Page Components/AboutSection';
import DepartmentSec from '../components/Home Page Components/Department Section/DepartmentSec';

const AboutPage = () => {
    return (
        <main>
            <section className='pt-44'>
                {/* heading  */}
                <div className='flex flex-col items-center justify-center space-y-2' data-aos="zoom-in-right" data-aos-duration="1000">
                    <h1 className='text-gray-700 text-2xl lg:text-3xl uppercase'>About Us</h1>
                    <div className='h-1 w-16 rounded-md bg-primary'></div>
                </div>

                {/* about company section  */}
                <AboutSection />

                {/* founder section  */}
                {/* <FounderSection /> */}

                {/* department section  */}
                <DepartmentSec />
            </section>
        </main>
    );
};

export default AboutPage;