import React from 'react';
import styles from "../../styles/herobanner.module.css";

const HeroBanner = () => {
    return (
        <main className={`${styles.bg} mt-20 w-full`} id="top">
            <div style={{backgroundColor: `rgba(255,255,255,0.7)`, height:"400px"}} >
                <div className='max-w-screen-lg mx-auto flex flex-col md:flex-col lg:flex-row justify-between' >
                    <div className='pt-36 lg:pt-44 pl-12' data-aos="fade-right" data-aos-duration="1000">

                        <h1 className='text-center lg:text-left text-black font-normal text-xl lg:text-4xl home_Banner_text'> Garments accessories Manufacturers </h1>
                        <h1 className='text-center lg:text-left text-black  text-lg lg:text-2xl home_Banner_text lg:pt-1'>in China & Bangladesh</h1>
                    </div>

                    {/* image  */}
                    <div className='w-84 h-48 lg:h-96 mt-4'>
                        <img className='w-full h-full object-cover' src="../../../images/lace.jpg" alt="lace" />
                    </div>
                </div>
            </div>
            
        </main>
    );
};

export default HeroBanner;

 