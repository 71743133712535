import React from 'react';
import { MdOutlineNavigateNext } from 'react-icons/md';
import { useNavigate } from "react-router-dom";

const LocationSection = () => {
    const {navigate} = useNavigate();

    // const sectionStyle = {
    //     width: "100%",
    //     height: "680px",
    //     backgroundPosition: 'center center',
    //     backgroundImage: "url(../../../../../images/susBG.jpg)",
    //     backgroundAttachment: "fixed",
    //     backgroundSize: "cover"
    // };

    return (
        <main >
            <section className='max-w-screen-lg mx-auto py-16 overflow-hidden md:pl-16 lg:pl-16'>
                <div className="flex flex-col md:flex-row lg:flex-row items-center justify-between pt-12 md:pt-0 lg:pt-0">

                    {/* heading  */}
                    <div className='flex flex-col items-center lg:items-start space-y-4 pt-6 lg:pt-0 order-2 lg:order-1 md:order-1' data-aos="zoom-in-right" data-aos-duration="1000">
                        <h1 className='text-2xl lg:text-3xl text-gray-800'>Find Us on the map</h1>
                        <button className="bg-primary px-4 py-4 border-primary rounded-lg text-white hover:bg-white hover:text-primary transition duration-500 w-32 text-xs hover:translate-x-2 flex items-center space-x-2" onClick={() => navigate('/location')}>
                            <span>See </span>
                            <MdOutlineNavigateNext className="text-sm" />
                        </button>
                    </div>

                    {/* map  */}
                    <div className='order-1 md:order-2 lg:order-2' data-aos="zoom-in-left" data-aos-duration="1000">
                        <iframe title="honggyuan" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228.04364387605244!2d90.40073343228444!3d23.86484471769977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c502ca70e809%3A0xcd93ca61f9a15cc6!2sHongyuan!5e0!3m2!1sen!2sbd!4v1673804937646!5m2!1sen!2sbd" width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                </div>
            </section>
        </main>
    );
};

export default LocationSection;