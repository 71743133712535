
import React from 'react';

const CustomerCard = ({ item }) => {
    // const [showTitle, setShowTitle] = useState(false)

    return (
        <div key={item?.id} className=" overflow-hidden flex flex-col space-y-3 items-center justify-center" data-aos="zoom-out-up" data-aos-duration="1000">

            <div className='rounded-full h-18 w-18 bg-white p-4 box-border'  >

                <img className='w-16 object-contain p-4 box-border h-16 hover:origin-center transform hover:rotate-180 hover:scale-0 transition duration-500' src={item?.img} alt={item?.title} />


            </div>
            {/* <div className='rounded-full h-18 w-18 bg-white p-4 box-border ' onMouseEnter={() => setShowTitle(true)} onMouseLeave={() => setShowTitle(false)}>

                {!showTitle ? (
                    <img className='w-16 object-contain h-full hover:origin-center transform hover:rotate-180 hover:scale-0 transition duration-500' src={item?.img} alt={item?.title} />
                ) : (
                    <div className='w-18'>
                        <h2>{item?.title} </h2>
                    </div>
                )}

            </div> */}
            <h3 className='text-center text-white text-xl'>{item?.number}</h3>
        </div>
    );
};

export default CustomerCard;