import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import Heading from '../Heading/Heading';

const DepartmentSec = () => {
    const [departments, setDepartments] = useState([])

    useEffect(() => {
        axios.get('./departments.json')?.then(res => {
            setDepartments(res?.data?.departments)
        })

    }, [])

    return (
        <section className='max-w-screen-lg mx-auto py-16 overflow-hidden px-4 lg:px-0'>

            <div className='flex flex-col space-y-14'>

                {/* heading  */}
                <div data-aos="zoom-in-right" data-aos-duration="1000">
                    <Heading title="Our Departments" subTitle="Production" />
                </div>

                {/* department  */}
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6  lg:gap-6 '>
                    {departments?.map(item => {
                        return (
                            <div key={item?.id} className="bg-white rounded-lg  box-border flex flex-col space-y-2" data-aos="zoom-in" data-aos-duration="1000">
                                <div className='w-full h-36 overflow-hidden rounded-tl-lg rounded-tr-lg'>
                                    <img src={item?.img} alt={item?.id} className="object-cover hover:scale-125 transition duration-500 w-full h-full" />
                                </div>

                                <div className="py-2">
                                    <h1 className='text-center text-sm text-gray-500'>{item?.title}</h1>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {/* more btn */}
                <div className="flex justify-center items-center" data-aos="zoom-in-right" data-aos-duration="1000" > 
                    <Link to="/about">
                        <Button title="More About The Company" />
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default DepartmentSec;