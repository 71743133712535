import React from 'react';
import SubFooter from '../components/Footer Section/SubFooter';
import CollectionSec from '../components/Home Page Components/Collection Section/CollectionSec(V3)';
import CustomersSec from "../components/Home Page Components/Customers Section/CustomersSec";
import DepartmentSec from '../components/Home Page Components/Department Section/DepartmentSec';
import Heading from '../components/Home Page Components/Heading/Heading';
import HeroBanner from '../components/Home Page Components/HeroBanner';
import LocationSection from '../components/Home Page Components/Location Section/LocationSection';
import ProductsSec from '../components/Home Page Components/Products Section/ProductsSec';
// import WelcomeSec from '../components/Home Page Components/Welcome Section/WelcomeSec';



const HomePage = () => {
    return (
        <>
            <HeroBanner />
            {/* <WelcomeSec /> */}
            <>
                {/* heading  */}
                <div data-aos="zoom-in-right" data-aos-duration="1000" className='pt-24'>
                    <Heading title="Our Products" subTitle="Categories" />
                </div>
                <ProductsSec />
            </>
            <CollectionSec />
            <DepartmentSec />
            {/* <SustainabilitySec /> */}
            <LocationSection />
            <CustomersSec />
            <SubFooter />
        </>
    );
};

export default HomePage;