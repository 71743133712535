import axios from 'axios';
import { React, useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Heading from '../Heading/Heading';

// import Swiper core and required modules

const CollectionSec = () => {
    const [sliderImg, setSliderImg] = useState([])
    let currentYear = new Date().getFullYear() 
    useEffect(() => {
        axios.get('./collectionSlider(V2).json')?.then(res => {
            setSliderImg(res?.data?.collectionImg)
        })

    }, [])


    return (
        <section className='max-w-screen-4xl mx-auto py-16 overflow-hidden'>
            <main >
                <div className='flex flex-col space-y-14'>

                    {/* heading  */}
                    <div data-aos="zoom-in-right" data-aos-duration="1000">
                        <Heading title="Our Lastest Collections" subTitle={currentYear} />
                    </div>

                </div>


                {/* collections  */}
                <div className='flex flex-col space-y-14 my-12 px-6'>



                    {/* products  */}
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 md:gap-8 lg:gap-10 '>
                        {sliderImg?.map(item => {
                            return (
                                <div key={item?._id} className="bg-white cursor-pointer rounded-lg box-border flex flex-col space-y-3"
                                    data-aos="zoom-in" data-aos-duration="1000" >
                                    <div className='w-full h-36 overflow-hidden rounded-lg'>
                                        <img src={item?.img} alt={item?.id} className="object-cover hover:scale-125 transition duration-500" />
                                    </div>

                                    <div>
                                        <h1 className='text-center text-sm text-gray-500 pb-2'>{item?.title}</h1>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </main>
        </section>
    );
};

export default CollectionSec;