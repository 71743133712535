import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const ProductsSec = () => {
    const [products, setProducts] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('./products.json')?.then(res => {
            setProducts(res?.data?.PRODUCTS_CATEGORY)
        })

    }, [])

    return (
        <section className='max-w-screen-lg mx-auto py-16 overflow-hidden px-4 lg:px-0'>

            <div className='flex flex-col space-y-14'>

              

                {/* products  */}
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 md:gap-8 lg:gap-10 '>
                    {products?.map(item => {
                        return (
                            <div key={item?._id} className="bg-white cursor-pointer rounded-lg p-4 box-border flex flex-col space-y-3" onClick={() => {
                                navigate(`/${item?.title?.toLowerCase()}`);
                            }} data-aos="zoom-in" data-aos-duration="1000" >
                               <div className='w-full h-36 overflow-hidden rounded-lg'>
                                    <img src={item?.img} alt={item?._id} className="object-cover hover:scale-125 transition duration-500" />
                               </div>

                               <div>
                                <h1 className='text-center text-sm text-gray-500'>{item?.title}</h1>
                               </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </section>
    );
};

export default ProductsSec;