import React from 'react';
import { MdOutlineNavigateNext } from 'react-icons/md';

const Button = ({title}) => {

    return (
        <div>
            <button className="bg-primary px-4 py-4 border-white rounded-lg text-center text-white hover:bg-white transition duration-500 hover:text-primary hover:border-primary border text-xs hover:translate-x-2 flex items-center space-x-2">
                <span>{title} </span>
                <MdOutlineNavigateNext className="text-sm" />
            </button>
        </div> 
    );
};

export default Button;