import React from "react";
import { useForm } from "react-hook-form";


const ContactForm = () => {
    // const [name, setName] = useState("");
    // const [email, setEmail] = useState("");
    // const [subject, setSubject] = useState("");
    // const [message, setMessage] = useState("");
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {

        // axios.post('https://localhost:5000/messages', {
        //     name:"Kabid"
        // })
        //     .then(function (response) {
        //         console.log(response);
        //         alert('Yes')

        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //         alert('No')
        //     });
        window.open(
            `mailto:monower@hongyuanmetal.net?subject=${encodeURIComponent(
                data.subject
            )}&body=${encodeURIComponent(data.name)} (${encodeURIComponent(
                data.email
            )}): ${encodeURIComponent(data.message)}`
        );

    };



    return (
        <section >
            <div className="bg-white rounded-lg p-6 box-border">
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-3">

                    {/* name  */}
                    <div className="flex flex-col space-y-1">
                        <input type="text" className="border-2 border-gray-300 bg-white p-3 rounded outline-none text-sm w-96" placeholder="Name" {...register("name", { required: true })} />
                        {errors.name && <span className="text-red-600 text-xs">*You must give your name*</span>}
                    </div>

                    {/* email  */}
                    <div className="flex flex-col space-y-1">
                        <input type="email" className="border-2 border-gray-300 bg-white p-3 rounded outline-none text-sm w-96" placeholder="Email" {...register("email", { required: true })} />
                        {errors.email && <span className="text-red-600 text-xs">*You must give your email*</span>}
                    </div>


                    {/* phone  */}
                    <div className="flex flex-col space-y-1">
                        <input type="number" className="border-2 border-gray-300 bg-white p-3 rounded outline-none text-sm w-96" placeholder="Phone Number" {...register("phoneNumber", { required: true })} />
                        {errors.phoneNumber && <span className="text-red-600 text-xs">*You must give your phone number*</span>}
                    </div>


                    {/* country  */}
                    <div className="flex flex-col space-y-1">
                        <input type="text" className="border-2 border-gray-300 bg-white p-3 rounded outline-none text-sm w-96" placeholder="Country" {...register("country", { required: false })} />
                        {errors.country && <span className="text-red-600 text-xs">*You must give your country name*</span>}
                    </div>

                    {/* subject  */}
                    <div className="flex flex-col space-y-1">
                        <input type="text" className="border-2 border-gray-300 bg-white p-3 rounded outline-none text-sm w-96" placeholder="Subject" {...register("subject", { required: true })} />
                        {errors.subject && <span className="text-red-600 text-xs">*You must give subject*</span>}
                    </div>
                    {/* message  */}
                    <div className="flex flex-col space-y-1">
                        <textarea type="text" className="border-2 border-gray-300 bg-white p-3 rounded outline-none text-sm w-96 h-36" placeholder="Message"  {...register("message", { required: true })}>

                        </textarea>
                        {errors.message && <span className="text-red-600 text-xs">*You must give your messages*</span>}
                    </div>

                    <div className=" flex flex-row justify-end w-full">
                        <input type="submit" className="bg-primary w-24 cursor-pointer  px-4 py-4 border-white rounded-lg text-center text-white hover:bg-white transition duration-500 hover:text-primary hover:border-primary border text-xs hover:translate-x-2" />

                    </div>

                </form>
            </div>

        </section>
    );
};

export default ContactForm;


